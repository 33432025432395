export const TIMELOCALES = [
  {
    key: "vi",
    local: "vi-VN",
  },
  {
    key: "en",
    local: "en-US",
  },
  {
    key: "ja",
    local: "ja-JP-u-ca-japanese",
  },
  {
    key: "ko",
    local: "ko-KR",
  },
];

export const LANGUAGE = ["vi", "en"];
